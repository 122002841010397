import React, { Component } from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import RouteLayout from "./Admin/Layout/RouteLayout";
import HomeCuestionario from './Admin/Pages/HomeCuestionario';
import ControlPreguntasAbiertas from './Admin/Pages/ControlPreguntasAbiertas';
import ControlCategoriaRespuesta from './Admin/Pages/ControlCategoriaRespuesta';
import ControlClasificacionCategoria from './Admin/Pages/ControlClasificacionCategoria';
import ControlPreguntaFinal from './Admin/Pages/ControlPreguntaFinal';
import UriPage from "./Admin/Pages/UriPage";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                        <RouteLayout path="/home/:token" component={HomeCuestionario} />
                        <RouteLayout path="/preguntas-abiertas/:etapa" component={ControlPreguntasAbiertas} />
                        <RouteLayout path="/pregunta-brecha/:etapa" component={ControlPreguntasAbiertas} />
                        <RouteLayout path="/preguntas-categoria-respuestas/:etapa" component={ControlCategoriaRespuesta} />
                        <RouteLayout path="/preguntas-clasificacion-categoria/:etapa" component={ControlClasificacionCategoria} />
                        <RouteLayout path="/pregunta-final/:etapa" component={ControlPreguntasAbiertas} />
                        <RouteLayout path="/uri/:keyUri" component={UriPage} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
