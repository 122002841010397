import http from "../../Http/Http-processcommon";
const NombreDelModulo = "Usuarios";

class CategoriaRespuestaDataService {
    async create(data) {
        return await http.post(`/${NombreDelModulo}/PostRespuestaCategoria`, data);
    }
    async getAllRespuestasCategoria(idUsuario, etapa) {
        return await http.get(`/${NombreDelModulo}/GetRespuestasFiltradas?idUsuario=${idUsuario}&etapa=${etapa}`);
    }
    async getCategorias() {
        return await http.get(`/${NombreDelModulo}/GetCategorias`);
    }
    async getEtapaNoActivaOFinalizada(idEtapa) {   
        return await http.get(`/${NombreDelModulo}/GetEtapaNoActivaOFinalizada?IdEtapa=${idEtapa}`);
    }
    async getCategoriasYaAsignadas(idUsuario, idEtapa) {   
        return await http.get(`/${NombreDelModulo}/GetCategoriasYaAsignadas?IdUsuario=${idUsuario}&IdEtapa=${idEtapa}`);
    }
}

export default new CategoriaRespuestaDataService();