import axios from "axios";


let instance = axios.create({
    baseURL: 'https://utilsmorantapi.azurewebsites.net/api/',
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    async config => {
        // const token = "Bearer 7CLDCMDwEcUSHpzaJkL4SWTVl7CgOf3MYlfRaZ98dqYcJ7HB664uRtX2X7gn";
        // config.headers.Authorization = token;
        return config;
    });

export { instance as default };
