import React from 'react';
import ClasificacionCategoriaDataService from "../../Services/PreguntaBrecha/ClasificacionCategoria.Service";
import HomeService from "../../Services/PreguntasAbiertas/PreguntasAbiertas.Service";
import Spinner from '../../components/Spinner';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// const { DragDropContext, Draggable, Droppable } = window.ReactBeautifulDnd;
const ReactDOM = window.ReactDOM;

// fake data generator
const getItems = (count) => Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: "7px",
    margin: `0 0 ${grid}px 0`,
    borderTop: "1px solid var(--color-gray-primary)",
    borderBottom: "1px solid var(--color-gray-primary)",
    // change background colour if dragging
    background: isDragging ? '#fff' : '#fff',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#fff' : '#fff',
    padding: grid,
    width: "100%"
});

class ControlClasificacionCategoria extends React.Component {
    constructor(props) {
        super(props);
        const etapa = this.props.match.params.etapa === undefined ? 0 : this.props.match.params.etapa;
        this.state = {
            loading: false,
            etapa: etapa,
            dataTotal: [],
            barValue: 0,
            messageSpinner: "Espere un momento...",
            encuestaEnabled: true,
            infoModal: false,
            tabActive: 0,
            Session: 0,
            modal: false,
            respuestasCategoria: [],
            modalRespuestasCategoria: false
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    // Handlers
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const dataTotal = reorder(
            this.state.dataTotal,
            result.source.index,
            result.destination.index
        );
        dataTotal.forEach((x, i) => {
            x.dataOrden.orden = i
        })
        this.setState({ dataTotal });
    }

    handlerBar = (value, index) => {
        let dataTotal = this.state.dataTotal;
        dataTotal[index].dataCalificacion.calificacion = Number(value);
        this.setState({ dataTotal: dataTotal })
    }
    handlerGoUp = (index) => {
        let indexDestination = index === 0 ? (this.state.dataTotal.length - 1) : index--;
        const dataTotal = reorder(
            this.state.dataTotal,
            index,
            indexDestination
        );
        dataTotal.forEach((x, i) => {
            x.dataOrden.orden = i
        })
        this.setState({ dataTotal });
    }
    handlerGoDown = (index) => {
        let indexDestination = index === (this.state.dataTotal.length - 1) ? 0 : index++;
        const dataTotal = reorder(
            this.state.dataTotal,
            index,
            indexDestination
        );
        dataTotal.forEach((x, i) => {
            x.dataOrden.orden = i
        })
        this.setState({ dataTotal });
    }
    //
    componentDidMount = async () => {
        await this.getSesion()
        if (await this.getEtapaNoActivaOFinalizada()) {
            let haRespondido = await this.GetClasificacionOrdenYaAsignados(localStorage.getItem('idUsuario'), this.state.etapa);
            if (!haRespondido) {
                await this.GetCategorias();
            }
        }
    }
    //

    // Métodos
    GetClasificacionOrdenYaAsignados = async (idUsuario, idEtapa) => {
        let haRespondido = false;
        await ClasificacionCategoriaDataService.getClasificacionOrdenYaAsignados(idUsuario, idEtapa).then(response => {
            haRespondido = response.data.length > 0 ? true : false;
        })
        if (haRespondido) {
            this.setState({ messageSpinner: `Su respuesta ha sido enviada. Por favor espere las indicaciones del facilitador para dar clic en "Recargar"`, encuestaEnabled: false, loading: true })
        }
        return haRespondido;
    }
    getEtapaNoActivaOFinalizada = async () => {
        let etapaActiva = true;
        await ClasificacionCategoriaDataService.getEtapaNoActivaOFinalizada(this.state.etapa)
            .then(response => {
                if (!response.data.activo || response.data.finalizado) {
                    etapaActiva = false
                    this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
                }
            })
        return etapaActiva;
    }
    GetCategorias = async () => {
        let comboTotal = [];
        await ClasificacionCategoriaDataService.getCategorias(this.state.Session).then(response => {
            response.data.forEach((cat, indexOrden) => {
                if (cat.esDefault && cat.nombre !== "Otro") {
                    let itemOrden = {
                        idOrdenCategoria: 0,
                        idSesion: 0,
                        idCategoria: cat.idCategoria,
                        idUsuario: localStorage.getItem('idUsuario'),
                        orden: 0,
                        nombre: cat.nombre
                    };

                    let itemCalificacion = {
                        idCalificacionCategoria: 0,
                        idSesion: 0,
                        idCategoria: cat.idCategoria,
                        idUsuario: localStorage.getItem('idUsuario'),
                        calificacion: 0
                    }

                    let total = {
                        dataOrden: itemOrden,
                        dataCalificacion: itemCalificacion
                    }

                    comboTotal.push(total)
                }
            })
            this.setState({ dataTotal: comboTotal })
        });
    }

    EnviarRespuestas = async () => {

        let valid = true

        this.state.dataTotal.map((res, k) => {
            console.log(res?.dataCalificacion?.calificacion);
            if (res?.dataCalificacion?.calificacion === 0) {
                valid = false
            }
        })

        this.state.dataTotal.map((res, k) => {
            console.log(res?.dataOrden?.orden);
            if (res?.dataOrden?.orden === 0) {
                valid = false
            }
        })

        if (valid) {
            this.setState({ loading: true })
            let total = this.state.dataTotal;
            let dataOrden = [], dataCalificacion = [];
            total.forEach(x => {
                dataOrden.push(x.dataOrden)
                dataCalificacion.push(x.dataCalificacion)
            })
            await ClasificacionCategoriaDataService.createDataOrden(dataOrden).then(response => {
                console.log(response.data)
            });
            await ClasificacionCategoriaDataService.createDataCalificacion(dataCalificacion).then(response => {
                console.log(response.data)
            })
            this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
        }
    }

    GoNextRespuesta = async () => {

        let valid = true

        this.state.dataTotal.map((res, k) => {
            console.log(res?.dataOrden?.orden);
            if (res?.dataOrden?.orden === 0) {
                valid = false
            }
        })
        if (valid) {
            let tabActive = this.state.tabActive;
            let dataLength = 2;

            if ((tabActive + 1) <= (dataLength - 1)) {
                this.setState({ tabActive: tabActive + 1 })
            } else {
                this.setState({ tabActive: 0 })
            }
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 10);
        }


    }

    GoPrevRespuesta = async () => {
        let tabActive = this.state.tabActive;
        let dataLength = 2;

        if ((tabActive - 1) >= (0)) {
            this.setState({ tabActive: tabActive - 1 })
        } else {
            this.setState({ tabActive: dataLength - 1 })
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }

    handlerBarOrden = async (value, index) => {
        let dataTotal = this.state.dataTotal;
        dataTotal[index].dataOrden.orden = Number(value);
        this.setState({ dataTotal: dataTotal })

        console.log(dataTotal);
    }



    /** ------------------------------------ >>>>>>>>>  Nuevos metodos <<<<<-------------------------------- */

    getSesion = async () => {
        // this.setState({ loading: true });
        await HomeService.getSesion()
            .then(response => {
                this.setState({ Session: response?.data?.idSesion });
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }

    getRespuestaCategoria = async (item) => {
        console.log(item.dataCalificacion.idCategoria);
        try {
            var res = await HomeService.respuestasCategoria(item.dataCalificacion.idCategoria).then(res => res)
            if (res?.data) {
                if (res.data.length > 0)
                    this.setState({ respuestasCategoria: res.data })
                console.log(res.data, "******");
            }
        } catch (error) {
            console.log("Error en vista", error);

        }
    }

    modalActions = () => {
        this.setState({ modalRespuestasCategoria: true })
    }

    renderLabels = (labels) => {
        return labels.map((label, index) => (
            <div key={index} className="slider-label" style={{ left: `${label.position}%` }}>
                {/* <div className="label-line"></div> */}
                <div className="label-text">{label.text}</div>
            </div>
        ));
    };

    //
    render() {
        const { item, index } = this.props;

        const importanciaLabels = [
            { position: 0, text: 'Nada importante' },
            { position: 25, text: 'Poco importante' },
            { position: 50, text: 'Algo importante' },
            { position: 75, text: 'Muy importante' }
        ];

        const cumplimientoLabels = [
            { position: 0, text: 'Se incumple totalmente' },
            { position: 25, text: 'Se incumple parcialmente' },
            { position: 50, text: 'Se cumple poco' },
            { position: 75, text: 'Se cumple mucho' }
        ];

        return (
            <div className="page-container">
                <div className={`modalContainer ${this.state.modalRespuestasCategoria ? "active" : ""}`}>


                    <div>
                        <div className="modalMessage modalAgregarCategoria">
                            <span className="close" onClick={() => {
                                this.setState({ modalRespuestasCategoria: false })
                            }}>&times;</span>
                            <div>
                                <h1 className='text-primary margin-bottom-h1'> Respuestas de la categoría</h1>

                                {this.state.respuestasCategoria.length > 0 ? <>
                                    {this.state.respuestasCategoria.map((res, key) => {
                                        return (
                                            <div key={key}>
                                                <div className="modal-content">
                                                    <div className="modal-body"> <strong> {key + 1}.- {res.respuesta}</strong></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </> : "Sin respuestas"}

                            </div>

                            <div className="fin-forcer">
                                <button className="btn-cancelar2 bt" id="cancelBtn" onClick={() => {

                                    this.setState({ modalRespuestasCategoria: false })
                                }}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Spinner show={this.state.loading} message={this.state.messageSpinner} />
                <div className="title"><h1>ABP</h1></div>
                <div className="subtitle"><p>Califique la importancia de cada una de las categorías arrastrando la barra horizontal de un lado a otro según el nivel de importancia que tiene cada categoría para usted.</p></div>
                <div className="SliderQuestionContainer mt-25">
                    <div className="SliderQuestion" style={{ width: "95%" }}>
                        <div className="LeftArrow">
                            {/* <button onClick={(e) => { this.GoPrevRespuesta() }} type="button"><i className="fas fa-caret-left"></i></button> */}
                        </div>
                        <div className="Screen">
                            <div className="TabsContainer">
                                <div className="TabsContent">
                                    <div className={`Tab ${this.state.tabActive === 0 ? "active" : ""}`}>
                                        <div className="OrdenContainer">
                                            <div className="OrdenHeaders">
                                                <p>Categoría</p>
                                                <p>Nivel de importancia y cumplimiento</p>
                                            </div>
                                            {this.state.dataTotal.map((item, index) => (
                                                <div className="OrdenItem" key={index}>
                                                    <div className="categoria">
                                                        <p><h3>{item.dataOrden.nombre}</h3></p>

                                                        <div className='btn-mas'>
                                                            <Button className="btn-primary-custom btn-btn-mas" onClick={async () => {
                                                                await this.getRespuestaCategoria(item)
                                                                this.modalActions()
                                                            }}>Más</Button>
                                                        </div>

                                                    </div>

                                                    {/* <div className='row'> */}
                                                    <div className="nivel2 col slider-wrapper">

                                                        <div className="slider-label line-25"></div>
                                                        <div className="slider-label line-50"></div>
                                                        <div className="slider-label line-75"></div>

                                                        <div className="slider-label range-text-1">Nada importante</div>
                                                        <div className="slider-label range-text-2">Poco importante</div>
                                                        <div className="slider-label range-text-3">Algo importante</div>
                                                        <div className="slider-label range-text-4">Muy importante</div>

                                                        <label htmlFor="">Mínimo</label>
                                                        <label htmlFor="">Máximo</label>
                                                        <input type="range" value={item.dataOrden.orden} onChange={(e) => { this.handlerBarOrden(e.currentTarget.value, index) }} max={100} min={0.0} />
                                                        {/* {this.renderLabels(importanciaLabels)} */}
                                                        <div className='textPorcentaje'>{item.dataOrden.orden}</div>
                                                    </div>

                                                    <div className="nivel col slider-wrapper">

                                                        <div className="slider-label line-25 cumplimiento"></div>
                                                        <div className="slider-label line-50 cumplimiento"></div>
                                                        <div className="slider-label line-75 cumplimiento"></div>

                                                        <label htmlFor="">Mínimo</label>
                                                        <label htmlFor="">Máximo</label>
                                                        <input type="range" value={item.dataCalificacion.calificacion} onChange={(e) => { this.handlerBar(e.currentTarget.value, index) }} max={100} min={0.0} />

                                                        <div className="slider-label range-text-1 cumplimiento">Se incumple totalmente</div>
                                                        <div className="slider-label range-text-2 cumplimiento">Se incumple parcialmente</div>
                                                        <div className="slider-label range-text-3 cumplimiento">Se cumple poco</div>
                                                        <div className="slider-label range-text-4 cumplimiento">Se cumple mucho</div>

                                                        <div className='textPorcentaje'>{item.dataCalificacion.calificacion}</div>
                                                    </div>
                                                    {/* </div> */}


                                                </div>
                                            ))}
                                            {
                                                this.state.encuestaEnabled ?
                                                    <button onClick={() => { this.EnviarRespuestas() }} className="btn primary">Enviar respuestas</button>

                                                    // <button onClick={() => { this.GoNextRespuesta() }} className="btn primary">Siguiente</button>
                                                    // <button onClick={() => {this.EnviarRespuestas()}} className="btn primary">Enviar respuestas</button>
                                                    :
                                                    <button onClick={() => { }} disabled className="btn primary">Respuestas enviadas</button>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className={`Tab ${this.state.tabActive === 1 ? "active" : ""}`}>
                                        <div className="OrdenContainer">
                                            <div className="OrdenHeaders">
                                                <p>Categoría</p>
                                                <p>Nivel de cumplimiento</p>
                                            </div>
                                            {this.state.dataTotal.map((item, index) => (
                                                <div className="OrdenItem" key={index}>
                                                    <div className="categoria">
                                                        <p>{item.dataOrden.nombre}</p>
                                                    </div>
                                                    <div className="nivel">
                                                        <label htmlFor="">Mínimo</label>
                                                        <label htmlFor="">Máximo</label>
                                                        <input type="range" value={item.dataCalificacion.calificacion} onChange={(e) => { this.handlerBar(e.currentTarget.value, index) }} max={100} min={0} />
                                                    </div>
                                                </div>
                                            ))}
                                            <button onClick={() => { this.GoPrevRespuesta() }} className="btn primary">Anterior</button>
                                            {
                                                this.state.encuestaEnabled ?
                                                    <button onClick={() => { this.EnviarRespuestas() }} className="btn primary">Enviar respuestas</button>
                                                    :
                                                    <button onClick={() => { }} disabled className="btn primary">Respuestas enviadas</button>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="RightArrow">
                            {/* <button onClick={(e) => { this.GoNextRespuesta() }} type="button"><i className="fas fa-caret-right"></i></button> */}
                        </div>
                    </div>
                </div>
                <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                    <div className="modalMessage">
                        <div className="modalHeader">
                            <p>ABP Virtual</p>
                            <small>Sistema de consulta participativa</small>
                        </div>
                        <div className="modalBody">
                            <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                            <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                        </div>
                        <div className="modalFooter">
                            <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                        </div>
                    </div>
                </div>
                <div className="leftActions">
                    <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                </div>
            </div>
        )
    }
}

export default ControlClasificacionCategoria;