import http from "../../Http/Http-processcommon";
const NombreDelModulo = "Usuarios";

class PreguntasAbiertasDataService {
    async create(data) {
        return await http.post(`/${NombreDelModulo}/PostRespuesta`, data);
    }
    async createrescate(data) {
        return await http.post(`/${NombreDelModulo}/PostRespuestaCategoria`, data);
    }
    async update(id, data) {
        // return await http.post(`/${NombreDelModulo}/${id}`, data);
    }
    async getAll(idFilter, search = "", page = 1, rows = 4) {
        // return await http.get(`/${NombreDelModulo}?idFilter=${idFilter}&search=${search}&page=${page}&rows=${rows}`);
    }
    async getTotalRecords(idFilter, search) {
        // return await http.get(`/${NombreDelModulo}/totalrecords?idFilter=${idFilter}&search=${search}`);
    }
    async get(idEtapa, idUsuario) {
        return await http.get(`/${NombreDelModulo}/GetPregunta?IdEtapa=${idEtapa}&IdUsuario=${idUsuario}`);
    }
    async getSesion() {

        return await http.get(`/${NombreDelModulo}`);
    }
    async getEtapa(idSesion) {

        return await http.get(`/${NombreDelModulo}/GetEtapa?IdSesion=${idSesion}`);
    }
    async getUsuario(idSesion) {

        return await http.get(`/${NombreDelModulo}/ValidarUsuario?IdSesion=${idSesion}`);
    }
    async getEtapaNoActivaOFinalizada(idEtapa) {

        return await http.get(`/${NombreDelModulo}/GetEtapaNoActivaOFinalizada?IdEtapa=${idEtapa}`);
    }
    async delete(id) {
        // return await http.delete(`/${NombreDelModulo}/${id}`);
    }
    async patch(id) {
        // return await http.patch(`/${NombreDelModulo}/patch/${id}`);
    }

    // * ACTUALIZACION
    async getV2(idEtapa, idUsuario) {
        return await http.get(`/${NombreDelModulo}/GetPreguntaSesion?IdEtapa=${idEtapa}&IdUsuario=${idUsuario}`);
    }

    async respuestasCategoria(id) {
        try {
            var res = await http.get(`/${NombreDelModulo}/getRespuestasCategoria?Id=${id}`)
            return res;
        } catch (error) {
            console.log(error, "Error en el catch");
            return error;
        }
    }
}

export default new PreguntasAbiertasDataService();
