import http from "../../Http/Http-processcommon";
const NombreDelModulo = "Usuarios";

class ClasificacionCategoriaDataService {
    async createDataOrden(data) {
        return await http.post(`/${NombreDelModulo}/PostOrdenCategoria`, data);
    }
    async createDataCalificacion(data) {
        return await http.post(`/${NombreDelModulo}/PostCaliCategoria`, data);
    }
    async getCategorias(session) {
        return await http.get(`/${NombreDelModulo}/GetAprobadas?session=${session}`);
    }
    async getEtapaNoActivaOFinalizada(idEtapa) {

        return await http.get(`/${NombreDelModulo}/GetEtapaNoActivaOFinalizada?IdEtapa=${idEtapa}`);
    }
    async getClasificacionOrdenYaAsignados(idUsuario, idEtapa) {
        return await http.get(`/${NombreDelModulo}/GetClasificacionOrdenYaAsignados?IdUsuario=${idUsuario}&IdEtapa=${idEtapa}`);
    }
}

export default new ClasificacionCategoriaDataService();