import React from 'react';
import HomeService from "../../Services/PreguntasAbiertas/PreguntasAbiertas.Service";
const sesionConst = {
    idSesion: 0,
    activo: false
}
const etapaConst = {
    idEtapaSesion: 0,
    idSesion: 0,
    idEtapa: 0,
    finalizado: false,
    activo: false
}
class HomeCuestionario extends React.Component {
    constructor(props) {
        super(props);
        const token = this.props.match.params.token === undefined ? 0 : this.props.match.params.token;
        this.state = {
            token: token,
            loading: true,
            sesion: sesionConst,
            etapa: etapaConst,
            fields: [],
            consulta: null
        }
    }
    componentDidMount = async () => {
        if (this.state.token !== localStorage.getItem('keyJwt')) {
            localStorage.removeItem("idUsuario")
        }
        localStorage.setItem('keyJwt', this.state.token);
        console.log(this.state.token);
        await this.getSesion();
        if (this.state.sesion.idSesion !== 0 && this.state.sesion.activo === true) {
            if (localStorage.getItem('idUsuario') === null || localStorage.getItem('idUsuario') === 0) {
                console.log("Se ha creado un idUsuario");
                await this.getUsuario(this.state.sesion.idSesion);
            }
            await this.getEtapa(this.state.sesion.idSesion);

        }

        // this.state.consulta = setInterval(async () => {
        //     console.log("Sin dar click");
        //     // this.Comenzar()
        //     await this.getEtapa(this.state.sesion.idSesion);

        // }, 3000);
    }

    // componentWillUnmount = async () => {
    //     clearInterval(this.state.consulta);
    // }

    getSesion = async () => {
        this.setState({ loading: true });
        await HomeService.getSesion()
            .then(response => {
                let sesion = this.state.sesion;
                sesion = response.data;
                this.setState({ sesion: sesion });
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }
    getUsuario = async (idSesion) => {
        this.setState({ loading: true });
        await HomeService.getUsuario(idSesion)
            .then(response => {
                let usuario = response.data;
                console.log(usuario);
                localStorage.setItem('idUsuario', usuario);
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }
    getEtapa = async (sesion) => {
        this.setState({ loading: true });
        await HomeService.getEtapa(sesion)
            .then(response => {
                let etapa = this.state.etapa;

                etapa = response.data;
                this.setState({ etapa: etapa });
                if (etapa.idEtapa !== 0 && etapa.activo === true) {
                    switch (etapa.idEtapa) {
                        case 1:
                            this.props.history.push("/preguntas-abiertas/" + etapa.idEtapa);
                            break;
                        case 2:
                            this.props.history.push("/pregunta-brecha/" + etapa.idEtapa);
                            break;
                        // case 3:
                        //     this.props.history.push("/preguntas-categoria-respuestas/" + etapa.idEtapa);
                        //     break;
                        case 4:
                            this.props.history.push("/preguntas-clasificacion-categoria/" + etapa.idEtapa);
                            break;
                        case 5:
                            this.props.history.push("/pregunta-final/" + etapa.idEtapa);
                            break;
                        default:
                            this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
                            break;
                    }

                }
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }
    Comenzar = () => {
        document.location.reload(true);
    }

    render() {
        return (
            <><div className="page-container">
                <div className="title"><h1>ABP</h1></div>
                <div className="subtitle"><p>Espere a que el facilitador active la siguiente etapa</p></div>
                <button onClick={() => { this.Comenzar() }} className="btn primary" type="button">Recargar</button>

            </div></>
        )
    }
}
export default HomeCuestionario;