import http from "../Http/Http-processcommon2";
const NombreDelModulo = "ShortUri";

class UtilsDataService {

    async getURL(keyUri) {
        return await http.get(`/${NombreDelModulo}/${keyUri}`);
    }
}

export default new UtilsDataService();
