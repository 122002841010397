import React from 'react';

import { Redirect } from 'react-router-dom';


import UtilsService from "../../Services/Utils.Service";

class UriPage extends React.Component {
    constructor(props) {
        super(props);
        const keyUriParam = this.props.match.params.keyUri === undefined ? 0 : this.props.match.params.keyUri;
        this.state = {
            keyUri: keyUriParam
        }
    }
    componentDidMount = async () => {
        await this.getData();
    }

    getData = async () => {
        await UtilsService.getURL(this.state.keyUri)
            .then(response => {
                let data = response.data;
                console.log(data);
                if (data != null) {
                    window.location.href = data.url;
                    //this.props.history.push(data.url);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        return (
            <><div className="page-container">

            </div></>
        )
    }
}
export default UriPage;