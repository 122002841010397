import React from 'react';
import Spinner from '../../components/Spinner';

class ControlPreguntaFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            respuesta: "",
            messageSpinner: "Espere un momento...",
            infoModal: false
        }
    }

    // Handlers
    handlerChangeRespuesta = async (value) => {
        this.setState({respuesta: value})
    }
    //
    componentDidMount = async () => {
        console.log("Hola pregunta final")
    }
    //

    // Métodos
    
    //
    render() {
        return (
            <div className="page-container">
                <Spinner show={this.state.loading} message={this.state.messageSpinner}/>
               <div className="title"><h1>ABP</h1></div>
               <div className="subtitle"><p>Gracias por contribuir hasta ahora con sus respuestas, por último, pedimos de la manera más atenta se resuelva la pregunta final de esta evaluación.</p></div>
               <div className="TabsContainer">
                   <div className="TabsContent">
                       <div className="Tab active" data-ref="1">
                            <div className="questions-container">
                                <div className="question">
                                    <label htmlFor="">Pregunta final</label>
                                    <div className="description text-justify">
                                        <p className="no-border">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque fugit, dicta molestias quidem dolore perferendis velit blanditiis, quis quam explicabo praesentium eos quod, laudantium animi doloremque aliquid maxime magni accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione sed tempore rerum quas suscipit ea ipsam tempora est in, vero nemo maiores, aperiam ad voluptates itaque quidem pariatur vel expedita.</p>
                                    </div>
                                </div>
                                <div className="question">
                                    <label htmlFor="">Respuesta</label>
                                    <div className="description text-justify">
                                        <textarea placeholder="Escriba aquí su respuesta" onChange={(e)=>{this.handlerChangeRespuesta(e.currentTarget.value)}} value={this.state.respuesta}/>
                                    </div>
                                </div>
                            </div>
                            <button onClick={()=>{this.Comenzar()}} className="btn primary" type="button">Enviar respuesta</button>
                       </div>
                   </div>
               </div>
               <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                   <div className="modalMessage">
                       <div className="modalHeader">
                           <p>ABP Virtual</p>
                           <small>Sistema de consulta participativa</small>
                       </div>
                       <div className="modalBody">
                           <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                           <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                       </div>
                       <div className="modalFooter">
                           <button type="button" onClick={()=>{this.setState({infoModal: false})}} className="btn primary">Cerrar mensaje</button>
                       </div>
                   </div>
               </div>
               <div className="leftActions">
                   <button type="button" onClick={()=>{this.setState({infoModal: true})}}><i className="fas fa-info" type="button"></i></button>
               </div>
            </div>
        )
    }
}

export default ControlPreguntaFinal;